export function saveSessionStorage(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function deleteSessionStorage(key) {
  sessionStorage.removeItem(key);
}

export function getSessionStorage(key) {
  return JSON.parse(sessionStorage.getItem(key));
}
