// Description: All the utility functions related to chat history are defined here.

export const addNewChatToChatHistory = (chatData) => {
  // Check if there is Today Chat History exist or not, modified_date is in ISO format
  // If not then add newChatObj with date_created and modified_date is current date in ISO format.
  // If yes then add newChatObj with date_created is current date in ISO format and modified_date is the same as the last chat history.
  let newChatObj = {
    request_id: '',
    agent_id: '',
    id: null,
    archived:false,
    continuation: 1,
    customer_id: 1,
    user_hrid: '',
    date_created: '',
    date_modified: '',
    title: 'New Chat',
    interactions: [],
    metadata: '',
    isNewChat: true,
  };
  console.log('session-01',newChatObj);
  const today = new Date();
  const todayInISO = today.toISOString().split('T')[0];
  const todayChatHistory = chatData['Today']?.find(
    (chat) => chat.date_modified.split('T')[0] === todayInISO,
  );
  if (todayChatHistory?.id !== null) {
    newChatObj = {
      ...newChatObj,
      date_modified: new Date().toISOString(),
      date_created: new Date().toISOString(),
    };
    return newChatObj;
  }
  return null;
};

// Function to sort items based on modified_date
export const groupChatHistoryRecordsByDate = (response, groupedChatHistory = {}, pageNum) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const previous7Days = new Date(today);
  previous7Days.setDate(previous7Days.getDate() - 7);
  const previous30Days = new Date(today);
  previous30Days.setDate(previous30Days.getDate() - 30);

  const groupedData = pageNum === 1 ? {
    Today: [],
    Yesterday: [],
    'Previous 7 Days': [],
    'Previous 30 Days': [],
  } : {
    ...groupedChatHistory,
    Today: groupedChatHistory.Today || [],
    Yesterday: groupedChatHistory.Yesterday || [],
    'Previous 7 Days': groupedChatHistory['Previous 7 Days'] || [],
    'Previous 30 Days': groupedChatHistory['Previous 30 Days'] || [],
  };
  if (response && response.length > 0) {
    response.forEach((record) => {
      const recordDate = new Date(record?.date_modified || record?.date_created);
      if (recordDate >= today) {
        groupedData['Today'].push(record);
      } else if (recordDate >= yesterday) {
        groupedData['Yesterday'].push(record);
      } else if (recordDate >= previous7Days) {
        groupedData['Previous 7 Days'].push(record);
      } else if (recordDate >= previous30Days) {
        groupedData['Previous 30 Days'].push(record);
      } else {
        const monthYear = recordDate.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
        if (!groupedData[monthYear]) {
          groupedData[monthYear] = [];
        }
        groupedData[monthYear].push(record);
      }
    });
    return groupedData;
  }
};