
import CONFIG from 'config';
const uuidMap = new Map();
let counter = 1;
export const processBoldMarkdown = (text) => {
  return text?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
};

function fetchDigitsFromUUID(uuid) {
  if (!uuidMap.has(uuid)) {
    uuidMap.set(uuid, counter);
    counter++;
  }
  return uuidMap.get(uuid);
}
export const processCustomMarkdown = (markdownKey, text, toolData, tool) => {
  console.log('processCustomMarkdown-utils', markdownKey, ' text->', text, ' tooldata->', toolData, ' tool->', tool);
  if (!markdownKey) {
    return text;
  }
  const key = markdownKey?.replace(/[[\]]/g, '') || '';

  const citationNum = key || 0;

  // const key = markdownKey?.match(/\d+-[^-|\]]+/ig)?.[0]?.replace(/\[|\]/ig, '') || '';
  // const citationNum = markdownKey?.match(/\d+/ig)?.[0] || 0;
  let replacementContent = '';
  let output = '';
  // console.log("tooldata-key",toolData,"---",key)
  const ref = toolData?.[key];

  switch (tool) {
    case CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL:
      console.log('util-ref', ref);
      if (ref?.kp_cms_id && ref?.page) {
        replacementContent = `<sup><a href="${CONFIG.UI_URL.PREVIEW_LINK(ref?.kp_cms_id, ref?.page)}" target="_blank">${fetchDigitsFromUUID(citationNum)}</a></sup> `;
      }
      output = text.replaceAll(markdownKey, replacementContent);

      break;

    case CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL:
      replacementContent = markdownKey?.match(/:\s*([^\]]*)/)?.[1] || '';
      const replacementKey = markdownKey?.match(/-(.+?):/)?.[1] || '';

      // replacementContent = markdownKey?.match(/:\s*[^\]]*/)?.[0]?.replace(':', '') || '';
      // const replacementKey = markdownKey?.match(/s-[^:]+/)?.[0]?.replace('s-', '') || '';

      let replacementLink = '';
      const keyClone = markdownKey?.match(/^\[([a-z0-9]{32})-/)?.[1] || '';
      const refClone = toolData?.[keyClone];
      console.log('util-expert', replacementContent, ' ---', markdownKey, '---', refClone);
      // if(replacementContent && ref) {
      if (replacementContent) {
        const dateFilter = 'Past Two Years';

        switch (replacementKey) {
          case 'Name':
            replacementLink = CONFIG.NAVIGATOR_LINKS.PROFILE(refClone?.hrid);
            break;
          case 'Cases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(refClone?.hrid, refClone?.inferredQuery?.replace(/"|'/g, '') || '');
            break;
          case 'recentMaterials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(refClone?.hrid, refClone?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'recentCases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(refClone?.hrid, refClone?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'Materials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(refClone?.hrid, refClone?.inferredQuery?.replace(/"|'/g, '') || '');
        }
        replacementContent = `<a class="expertsLinks" target="_blank" href="${replacementLink}">${replacementContent}</a>`;
        // console.log("util-repla",replacementLink,"----",replacementContent)
      }

      output = text.replaceAll(markdownKey, replacementContent);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL:
      if (ref?.url) {
        const domain = (new URL(ref?.url))?.hostname;
        replacementContent = domain ? `<sup><a href="${ref?.url}" target="_blank">${domain}</a></sup>` : '';
      }
      output = text.replaceAll(markdownKey, replacementContent);
      break;
  }

  return [output, key];
};